import { MouseEventHandler, useEffect, useState } from "react";
import Img from "./image";
import Popup from "reactjs-popup";
import FlashMessages, { FlashMessagesType } from "../functions/flashMessages";
import SwitchSignInSignUp from "./switchSignInSignUp";
import UserManager from "../auth/userManager";
import AnnouncementBar from "./announcementBar";
import { WoocommerceService } from "../services/WoocommerceService";
import { annualPromotionApi } from "../apiStrapi/StrapiApiService";
import PartialLogin, { TypePartial } from "./partialLogin";
import { useRouter } from "next/router";
import { getRenewalUser } from "../apiRenewal";
import { RenewalUser } from "../apiRenewal/index";
import { getUserTaggings } from "../apiNest/NestApiService";

interface Menu {
  url: string;
  name: string;
}

export default function Header() {
  const userManager = new UserManager();
  const flashMessages = new FlashMessages();
  const [hamburgerOpened, setHamburgerOpened] = useState(false);
  const [annualSku, setAnnualSku] = useState("");
  const [renewalSku, setRenewalSku] = useState("");
  const [shopeeID, setShopeeID] = useState("");
  const [hasShopeeID, setHasShopeeID] = useState(false);
  const flashForgotPassword = flashMessages.getMessages(
    FlashMessagesType.forgotPasswordMessages
  );
  const [showAnnual, setShowAnnual] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);
  const router = useRouter();
  const [renewalUser, setRenewalUser] = useState<RenewalUser>();
  const [isPaidUser, setIsPaidUser] = useState<boolean>(false);

  const menuLogedIn: Menu[] = [
    { url: "/library", name: "คอร์สของฉัน" },
    {
      url: userManager.isLoggedIn() ? "/free-trial" : "/courses",
      name: userManager.isLoggedIn() ? "ทดลองเรียนฟรี" : "คอร์สทั้งหมด",
    },
    { url: "https://blog.cariber.co/", name: "บทความ" },
    { url: "/reviews", name: "รีวิว" },
    { url: "https://page.cariber.co/for-business", name: "สำหรับองค์กร" },
    { url: "https://page.cariber.co/about", name: "เกี่ยวกับเรา" },
  ];

  const menu: Menu[] = [
    { url: "/courses", name: "คอร์สทั้งหมด" },
    { url: "https://blog.cariber.co/", name: "บทความ" },
    { url: "/reviews", name: "รีวิว" },
    { url: "https://page.cariber.co/for-business", name: "สำหรับองค์กร" },
    { url: "https://page.cariber.co/about", name: "เกี่ยวกับเรา" },
  ];

  const menuUser: Menu[] = [
    { url: "/library", name: "คอร์สของฉัน" },
    { url: "/guard-contact", name: "การตั้งค่า" },
    { url: "/logout", name: "ออกจากระบบ" },
  ];

  const menuUserMobile: Menu[] = [
    { url: "/guard-contact", name: "การตั้งค่า" },
    { url: "/logout", name: "ออกจากระบบ" },
  ];

  useEffect(() => {
    window.addEventListener("resize", () => {
      setHamburgerOpened(false);
    });
    checkShopeeCredentials();
    fetchData();
    setTimeout(() => {
      setShow(true);
    }, 1);
  }, []);

  async function fetchData() {
    const data = await annualPromotionApi();
    const user = await getRenewalUser();
    const userTags = await getUserTaggings();

    if (user) {
      setRenewalUser(user);
    }
    if (userTags && !(userTags?.statusCode === 401)) {
      const paidUser = userTags?.filter((item) => {
        return item.tagging_name === "Paid user";
      });
      if (paidUser?.length > 0) {
        setIsPaidUser(true);
      }
    }
    setAnnualSku(data?.data?.attributes?.checkout_url);
    setRenewalSku(data?.data?.attributes?.renewal_url);
  }

  function checkShopeeCredentials(): void {
    const url = window.location.search || "";
    if (url?.indexOf("?id=") === -1) {
      return;
    }
    const base64ID = url?.replace("?id=", "") || "";
    if (base64ID) {
      setHasShopeeID(true);
      const shopeeID = new Buffer(base64ID, "base64").toString("ascii");
      setShopeeID(shopeeID);
      userManager.isLoggedIn() &&
        WoocommerceService.claimOrderIDWithCurrentUser(shopeeID);
      return;
    }
    setHasShopeeID(false);
    setShopeeID("");
  }

  function switchHamburger() {
    setHamburgerOpened(!hamburgerOpened);
  }

  function logoRedirect() {
    if (userManager.isLoggedIn() || window?.location?.pathname === "/") {
      window?.location?.reload();
    } else {
      router.push("/");
    }
  }

  return (
    <>
      {show && (
        <header className="header">
          <AnnouncementBar />
          {flashForgotPassword && (
            <div className="header-changed-password">
              <div className="row">
                <span className="fa fa-check-circle w-18 h-18"></span>
                <p className="m-0">
                  &nbsp;
                  {flashForgotPassword}
                </p>
              </div>
            </div>
          )}
          <div className="header-wrap">
            <div className="header-content header-content-desktop background-dark">
              <div className="container header-container media h-44">
                <div
                  className="logo w-120 h-42"
                  onClick={() => {
                    logoRedirect();
                  }}
                >
                  <Img
                    className="logo-image"
                    src="/header/header-logo.png"
                    width={120}
                    height={41.8833}
                    alt="Header Logo"
                  />
                </div>
                <div className="header-block header-switch-content header-block-menu media-body">
                  <div className="link-list justify-content-right">
                    {userManager.isLoggedIn() ? (
                      <div>
                        {menuLogedIn.map((value, index) => {
                          return (
                            <a
                              key={index}
                              href={value.url}
                              className={`link-list-link ${
                                isPaidUser && value.name === "ทดลองเรียนฟรี"
                                  ? "hidden"
                                  : ""
                              }`}
                            >
                              {value.name}
                            </a>
                          );
                        })}
                      </div>
                    ) : (
                      <div>
                        {menu.map((value, index) => {
                          return (
                            <a
                              href={value.url}
                              key={index}
                              className="link-list-link"
                            >
                              {value.name}
                            </a>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="header-block header-switch-content header-block-cta">
                  {showAnnual && (
                    <PartialLogin
                      checkout_url={
                        renewalUser?.renewal ? renewalSku : annualSku
                      }
                      type={TypePartial.AnnualSubscription}
                      render={
                        <a
                          className={`btn btn-small btn-full ${
                            userManager.isLoggedIn() &&
                            !renewalUser?.renewal &&
                            isPaidUser
                              ? "hidden"
                              : ""
                          }`}
                        >
                          {userManager.isLoggedIn() && renewalUser?.renewal
                            ? "ต่ออายุแพ็กเกจรายปี"
                            : "ซื้อแพ็กเกจรายปี"}
                        </a>
                      }
                    />
                  )}
                </div>
                {userManager.isLoggedIn() ? (
                  <div className="header-block header-switch-content header-block-user header-block-mr0">
                    <div className="user-avatar-block">
                      <Popup
                        trigger={
                          <div>
                            <Img
                              className="user-avatar"
                              src={
                                (userManager.getProfileImage() as string) ||
                                "/default_avatar.webp"
                              }
                              width={40}
                              height={40}
                              alt="default_avatar.webp"
                            />
                          </div>
                        }
                        position="bottom right"
                        on="click"
                        closeOnDocumentClick
                        contentStyle={{ padding: "0px", border: "none" }}
                        arrow={false}
                      >
                        <div className="menu">
                          {menuUser.map((value, index) => {
                            return (
                              <a
                                key={index}
                                href={value.url}
                                className="menu-item"
                              >
                                {value.name}
                              </a>
                            );
                          })}
                        </div>
                      </Popup>
                    </div>
                  </div>
                ) : (
                  <div className="header-block header-switch-content header-block-user header-block-mr0">
                    <div className="user">
                      <span className="user-login">
                        <Popup
                          className="popup-auth"
                          open={shopeeID !== ""}
                          trigger={
                            <button className="btn btn-link btn-small color-primary">
                              เข้าสู่ระบบ
                            </button>
                          }
                          modal
                          closeOnDocumentClick={false}
                        >
                          {(close: MouseEventHandler<HTMLButtonElement>) => {
                            return (
                              <div className="pop-modal">
                                <button className="close" onClick={close}>
                                  <p>&times;</p>
                                </button>
                                <SwitchSignInSignUp shopeeID={shopeeID} />
                              </div>
                            );
                          }}
                        </Popup>
                      </span>
                    </div>
                  </div>
                )}
                <div
                  className={`hamburger hidden-desktop ${
                    hamburgerOpened && "hamburger-opened"
                  }`}
                  onClick={switchHamburger}
                >
                  <div className="hamburger-slices">
                    <div className="hamburger-slice hamburger-slice-1" />
                    <div className="hamburger-slice hamburger-slice-2" />
                    <div className="hamburger-slice hamburger-slice-3" />
                    <div className="hamburger-slice hamburger-slice-4" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`header-content header-content-mobile background-dark ${
                hamburgerOpened ? "d-block" : "d-none"
              }`}
            >
              <div className="header-block header-switch-content header-block-menu">
                {userManager.isLoggedIn() ? (
                  <div className="link-list">
                    {menuLogedIn.map((value, index) => {
                      return (
                        <a
                          key={index}
                          className={`link-list-link ${
                            isPaidUser && value.name === "ทดลองเรียนฟรี"
                              ? "hidden"
                              : ""
                          }`}
                          href={value.url}
                        >
                          {value.name}
                        </a>
                      );
                    })}
                  </div>
                ) : (
                  <div className="link-list">
                    {menu.map((value, index) => {
                      return (
                        <a
                          key={index}
                          className="link-list-link"
                          href={value.url}
                        >
                          {value.name}
                        </a>
                      );
                    })}
                  </div>
                )}
                <div className="header-block header-switch-content header-block-cta">
                  <PartialLogin
                    checkout_url={renewalUser?.renewal ? renewalSku : annualSku}
                    type={TypePartial.AnnualSubscription}
                    render={
                      <a className="btn btn-small btn-full">
                        {userManager.isLoggedIn() && renewalUser?.renewal
                          ? "ต่ออายุแพ็กเกจรายปี"
                          : "ซื้อแพ็กเกจรายปี"}
                      </a>
                    }
                  />
                </div>
                {userManager.isLoggedIn() ? (
                  <div className="header-block header-switch-content header-block-menu">
                    <span className="link-list">
                      {menuUserMobile.map((value, index) => {
                        return (
                          <a
                            key={index}
                            href={value.url}
                            className="link-list-link"
                          >
                            {value.name}
                          </a>
                        );
                      })}
                    </span>
                  </div>
                ) : (
                  <div className="header-block header-switch-content header-block-user">
                    <span className="user-login">
                      <Popup
                        className="popup-auth"
                        trigger={
                          <button className="btn btn-link btn-small color-primary">
                            เข้าสู่ระบบ
                          </button>
                        }
                        modal
                        closeOnDocumentClick={false}
                      >
                        {(close: MouseEventHandler<HTMLButtonElement>) => {
                          return (
                            <div className="pop-modal">
                              <button className="close" onClick={close}>
                                <p>&times;</p>
                              </button>
                              <SwitchSignInSignUp />
                            </div>
                          );
                        }}
                      </Popup>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
}
