import {FormEvent, MouseEventHandler, useEffect, useState} from "react";
import { getFacebookAuthToken, getGoogleAuthToken, loginApi } from "../apiNest/NestApiService";
import FormInput from "./formInput";
import ShowError from "./showError";
import Link from "next/link";
import Img from "./image";
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import UserManager from "../auth/userManager";
import router from "next/router";
import { Auth, AuthApiProps } from "../apiNest/models/content/auth";
import { WoocommerceService } from "../services/WoocommerceService";
import { AccountContent } from "../apiNest/models/content/account";

import { CredentialResponse } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import useWindowDimensions from "../functions/getWindowDimensions";

interface LoginProps {
  callbackButton: MouseEventHandler<HTMLButtonElement>,
  shopeeID: string | null,
}

export default function Login({ callbackButton, shopeeID }: LoginProps) {
  const userManager = new UserManager();
  const [formLogin, setFormLogin] = useState({
    email: "",
    password: "",
  })
  const [errorLogin, setErrorLogin] = useState({
    isError: false,
    message: "",
  })

  useEffect(() => {
    document.getElementById('form_submission_email')?.focus();
  }, [shopeeID]);

  const responseGoogle = async (response: CredentialResponse) => {
    setErrorLogin({
      isError: false,
      message: ""
    });
    const data = await getGoogleAuthToken({
      id_token: response.credential
    }) as Auth;
    if ((data.statusCode === 500 || !data)) {
      setErrorLogin({
        isError: true,
        message: "เข้าสู่ระบบล้มเหลว กรุณาลองใหม่อีกครั้ง"
      });
      return
    }
    const user: AccountContent | null = await userManager.saveToken(data.access_token, data.refresh_token);
    if (shopeeID && data.access_token) {
      WoocommerceService.claimOrderIDWithCurrentUser(shopeeID);
      return;
    }
    router.query?.redirect ? router.replace(router.query?.redirect as string) : await router.replace(user?.createdType === 'Imported' && !user?.has_password ? '/communication-page' : "/library");
  }

  const responseFacebook = async (response: ReactFacebookLoginInfo) => {
    setErrorLogin({
      isError: false,
      message: ""
    });
    if (response.accessToken) {
      const data = await getFacebookAuthToken({
        access_token: response.accessToken as string
      }) as Auth;
      if ((data.statusCode === 500 || !data)) {
        setErrorLogin({
          isError: true,
          message: "เข้าสู่ระบบล้มเหลว กรุณาลองใหม่อีกครั้ง"
        });
        return
      }
      const user: AccountContent | null = await userManager.saveToken(data.access_token, data.refresh_token);
      if (shopeeID && data.access_token) {
        WoocommerceService.claimOrderIDWithCurrentUser(shopeeID);
        return;
      }
      router.query?.redirect ? router.replace(router.query?.redirect as string) : await router.replace(user?.createdType === 'Imported' && !user?.has_password ? '/communication-page' : "/library");
    }
  }

  async function loginRequest(event?: FormEvent) {
    event && event.preventDefault();
    setErrorLogin({
      isError: false,
      message: ""
    });
    const formData: AuthApiProps = {
      username: formLogin.email,
      password: formLogin.password,
    };
    let data = await loginApi(formData);
    if (!data) {
      setErrorLogin({
        isError: true,
        message: "เข้าสู่ระบบล้มเหลว กรุณาลองใหม่อีกครั้ง"
      });
      return
    }
    if (!data.message) {
      const user: AccountContent | null = await userManager.saveToken(data.access_token, data.refresh_token);
      if (shopeeID && data.access_token) {
        WoocommerceService.claimOrderIDWithCurrentUser(shopeeID);
        return;
      }
      router.query?.redirect ? router.replace(router.query?.redirect as string) : await router.replace(user?.createdType === 'Imported' && !user?.has_password ? '/communication-page' : "/library");
    } else {
      setErrorLogin({
        isError: true,
        message: "อีเมลหรือรหัสผ่านไม่ถูกต้องกรุณาลองใหม่หรือเข้าสู่ระบบด้วย Google/Facebook",
      })
    }
  }

  const GoogleLoginWidth = () => {
    const { width } = useWindowDimensions();
    return width<400? `${width-60}px` : '360px';
  }

  return (
    <div>
      <h2 className="color-white text-center">
        เข้าสู่ระบบ
      </h2>
      <div className="column-center">
        <GoogleLogin
          width={GoogleLoginWidth()}
          shape={'pill'}
          onSuccess={responseGoogle}
          onError={() => {
            console.error('Google Login Failed');
          }}
        />
        <FacebookLogin
          appId={process.env.NEXT_PUBLIC_NEXTAUTH_FACEBOOK_CLIENT_ID as string}
          callback={responseFacebook}
          disableMobileRedirect={true}
          cssClass="btn btn-icon btn-full m-b-10 m-x-0 background-color-facebook"
          containerStyle={{ width: "100%", transition: "none" }}
          icon={
            <div className="icon-frame p-0">
              <Img src="/login/facebook-icon.png"
                width={30}
                height={30}
                alt="Facebook"
              />
            </div>
          }
          textButton="เข้าสู่ระบบด้วย Facebook"
        />
      </div>
      <hr />
      <div className="p-20">
        <div className="block-type-form text-center">
          <div className="block box-shadow-none">
            <div className="form">
              <form onSubmit={loginRequest}>
                <div className="wrap-text-fit">
                  {errorLogin.isError && (<ShowError message={errorLogin.message} />)}
                </div>
                <div className="form-group">
                  <label className="label" form="member-email">
                    อีเมล
                  </label>
                  <FormInput id={"email"}
                    type={"email"}
                    required={true}
                    placeholder={""}
                    emailError={'กรุณาระบุอีเมลให้ถูกต้อง'}
                    noInputError={"โปรดระบุอีเมลของคุณ"}
                    onChange={(e) => { formLogin.email = e.currentTarget.value; }}
                    minLength={0} />
                </div>
                <div className="form-group">
                  <label className="label" form="member-email">
                    รหัสผ่าน
                  </label>
                  <FormInput id={"password"}
                    type={"password"}
                    required={true}
                    placeholder={""}
                    tooShortError={"รหัสผ่าน (โปรดใช้อักขระอย่างน้อย 8 ตัว)"}
                    noInputError={"โปรดระบุรหัสผ่านของคุณ"}
                    onChange={(e) => { formLogin.password = e.currentTarget.value; }}
                    minLength={8} />
                </div>
                <button id="form-button" className="btn btn-solid btn-full btn-small" type="submit">
                  เข้าสู่ระบบ
                </button>
              </form>
            </div>
            <div className="login-bottom">
              <div className="color-white row justify-content-center">
                ไม่ได้เป็นสมาชิก?
                &nbsp;
                <button onClick={callbackButton} className="btn btn-small m-0 p-0 link-colorless color-white">
                  คลิกเพื่อสร้างบัญชีผู้ใช้งานใหม่
                </button>
              </div>
              <Link href={"/forgot-password"} passHref={true}>
                <a className="btn btn-small m-t-20 m-0 p-0 link-colorless color-white">
                  ลืมรหัสผ่าน?
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

