import { useRouter } from "next/router";
import { MouseEventHandler, useState } from "react";
import Popup from "reactjs-popup";
import UserManager from "../auth/userManager";
import CustomLogin from "./customLogin";
import Img from "./image";

export enum TypePartial{
  AnnualSubscription = "สมัครสมาชิกรายปี",
  SingleCourse = "ซื้อคอร์ส",
  None = ""
}

interface PartialLoginProps {
  checkout_url: string;
  type: TypePartial;
  render: JSX.Element;
}

export default function PartialLogin(props: PartialLoginProps) {
  const userManager = new UserManager();
  const router = useRouter();
  const [isPopup, setIsPopup] = useState(false);

  async function interestCourse(checkout_url: string) {
    if (userManager.isLoggedIn()) {
      checkout_url && await userManager.redirectCheckout(router, checkout_url);
    } else {
      // setIsPopup(true); removed partial login. might use later
      router.push(checkout_url).then(() => {});
    }
  }

  async function setCallbackButtonFN(checkout_url: string) {
    setIsPopup(false)
    if (userManager.isLoggedIn()) {
      checkout_url && await userManager.redirectCheckout(router, checkout_url);
    }
  }

  return (
    <>
      <div onClick={() => interestCourse(props.checkout_url)}>
        {props.render}
      </div>
      <Popup className="popup-auth"
        open={isPopup}
        modal
        onClose={() => setIsPopup(false)}
        closeOnDocumentClick={false}>
        {(close: MouseEventHandler<HTMLButtonElement>) => {
          return (
            <div className="pop-modal">
              <button className="close" onClick={close}>
                <p>
                  &times;
                </p>
              </button>
              <CustomLogin headerText={props.type} path={props.checkout_url} callbackButton={setCallbackButtonFN} />
            </div>
          )
        }}
      </Popup>
    </>
  )
}
