import React, {ChangeEventHandler, FormEvent, HTMLInputTypeAttribute, SyntheticEvent} from "react";

interface FormInputProps {
  id: string;
  label?: string;
  description?: string;
  type: HTMLInputTypeAttribute;
  required: boolean;
  placeholder?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  minLength?: number;
  maxLength?: number;
  value?: string | number;
  warning?: string;
  tooShortError?: string;
  noInputError?: string;
  emailError?: string;
  autocomplete?: string;
}

export default function FormInput({
                                    id,
                                    label,
                                    type,
                                    description,
                                    required,
                                    placeholder,
                                    onChange,
                                    minLength,
                                    maxLength,
                                    warning,
                                    tooShortError,
                                    emailError,
                                    noInputError,
                                    value,
                                    autocomplete = "on"
                                  }: FormInputProps) {

  const emailPattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

  return (
    <div className={`${id}-field form-group`}>
      {label && (
        <label className="control-label string color-black" htmlFor={`form_submission_${id}`}>
          {label} {required && (<span className="color-red">*<small>{warning}</small></span>)}
        </label>
      )}
      {description && (
        <p className="color-black f-s-12">
          {description}
        </p>
      )}
      <input id={`form_submission_${id}`}
             className="color-black form-control"
             name={id}
             type={type}
             onChange={onChange}
             required={required}
             placeholder={placeholder}
             autoComplete={autocomplete}
             minLength={minLength}
             maxLength={maxLength}
             onInvalid={(e: React.ChangeEvent<HTMLInputElement>) => {
               const inputValue = e.target.value;
               const message = (!inputValue && Boolean(noInputError?.length))
                 ? noInputError
                 : ((inputValue?.toString()?.length || 0) < (maxLength || 9) && Boolean(tooShortError?.length))
                   ? tooShortError
                   : (!emailPattern.test(inputValue) && Boolean(emailError?.length) && inputValue)
                      ? emailError
                      : null;
               return e.target?.setCustomValidity(message || '');
             }}
             onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
               return e.target?.setCustomValidity('');
             }}
             value={value}/>
    </div>
  )
}
