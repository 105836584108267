import { useState, FormEvent, MouseEventHandler } from "react";
import FormInput from "./formInput"
import ShowError from "./showError";
import Img from "./image";
import {
  registerApi,
  getFacebookAuthToken,
  getGoogleAuthToken,
} from "../apiNest/NestApiService";
import UserManager from "../auth/userManager";
import { useRouter } from "next/router";
import { RegisterApiProps, RegisterContent } from '../apiNest/models/content/register';
import { WoocommerceService } from "../services/WoocommerceService";
import {CredentialResponse, GoogleLogin} from "@react-oauth/google";
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import { Auth } from "../apiNest/models/content/auth";
import useWindowDimensions from "../functions/getWindowDimensions";

interface RegisterProps {
  callbackButton?: MouseEventHandler<HTMLButtonElement>,
  shopeeID: string | null,
}

export default function Register({ callbackButton, shopeeID }: RegisterProps) {
  const userManager = new UserManager();
  const router = useRouter();
  const [formRegister, setFormRegister] = useState({
    email: "",
    password: "",
    confirmPassword: ""
  });
  const [errorRegister, setErrorRegister] = useState({
    isError: false,
    message: "",
  });
  const [errorLogin, setErrorLogin] = useState({
    isError: false,
    message: "",
  });

  async function registerRequest(event: FormEvent) {
    event.preventDefault();
    if (formRegister.password !== formRegister.confirmPassword) {
      setErrorRegister({
        isError: true,
        message: "รหัสผ่านไม่ตรงกัน"
      })
      return
    }
    const formData: RegisterApiProps = {
      email: formRegister.email,
      password: formRegister.password
    }
    const data = await registerApi(formData) as RegisterContent;
    if (!data.message) {
      userManager.saveToken(data.access_token, data.refresh_token);
      if (shopeeID) {
        WoocommerceService.claimOrderIDWithCurrentUser(shopeeID);
        return;
      }
      await router.replace("/library");
    } else {
      let message = data.message.toString()
      if(message.includes("Key (email)=(")){
        message = message.replace("Key (email)=(", "");
        message = message.replace(") already exists.", "");
        message = `มีอีเมล และชื่อผู้ใช้งานนี้อยู่ในระบบแล้ว`;
      }
      setErrorRegister({
        isError: true,
        message: message,
      });
    }
  }

  const responseGoogle = async (response: CredentialResponse) => {
    setErrorLogin({
      isError: false,
      message: ""
    });
    const data = await getGoogleAuthToken({
      id_token: response.credential
    }) as Auth;
    if ((data.statusCode === 500 || !data)) {
      setErrorLogin({
        isError: true,
        message: "เข้าสู่ระบบล้มเหลว กรุณาลองใหม่อีกครั้ง"
      });
      return
    }
    userManager.saveToken(data.access_token, data.refresh_token);
    if (shopeeID && data.access_token) {
      WoocommerceService.claimOrderIDWithCurrentUser(shopeeID);
      return;
    }
    await router.replace("/library");
  }

  const responseFacebook = async (response: ReactFacebookLoginInfo) => {
    setErrorLogin({
      isError: false,
      message: ""
    });
    if (response.accessToken) {
      const data = await getFacebookAuthToken({
        access_token: response.accessToken as string
      }) as Auth;
      if ((data.statusCode === 500 || !data)) {
        setErrorLogin({
          isError: true,
          message: "เข้าสู่ระบบล้มเหลว กรุณาลองใหม่อีกครั้ง"
        });
        return
      }
      userManager.saveToken(data.access_token, data.refresh_token);
      if (shopeeID && data.access_token) {
        WoocommerceService.claimOrderIDWithCurrentUser(shopeeID);
        return;
      }
      await router.replace("/library");
    }
  }

  const GoogleLoginWidth = () => {
    const { width } = useWindowDimensions();
    return width<400? `${width-60}px` : '360px';
  }


  return (
    <div>
      <h2 className="color-white text-center">
        สร้างบัญชีผู้ใช้งาน
      </h2>
      <div className="column-center">
        <GoogleLogin
          width={GoogleLoginWidth()}
          shape={'pill'}
          onSuccess={responseGoogle}
          onError={() => {
            console.error('Google Login Failed');
          }}
        />
        <FacebookLogin
          appId={process.env.NEXT_PUBLIC_NEXTAUTH_FACEBOOK_CLIENT_ID as string}
          callback={responseFacebook}
          disableMobileRedirect={true}
          cssClass="btn btn-icon btn-full m-b-10 m-x-0 background-color-facebook"
          containerStyle={{width: "100%", transition: "none"}}
          icon={
            <div className="icon-frame p-0">
            <Img src="/login/facebook-icon.png"
              width={30}
              height={30}
              alt="Facebook"
            />
          </div>
          }
          textButton="ลงทะเบียนด้วย Facebook"
        />
      </div>
      <hr />
      <div className="p-20">
        <div className="block-type-form text-center">
          <div className="block box-shadow-none">
            <div className="form">
              <form onSubmit={registerRequest}>
                {errorRegister.isError && (<ShowError message={errorRegister.message} />)}
                {errorLogin.isError && (<ShowError message={errorLogin.message} />)}
                <div className="form-group">
                  <label className="label" form="member-email">
                    อีเมล
                  </label>
                  <FormInput id={"email"}
                    type={"email"}
                    required={true}
                    noInputError={"โปรดระบุอีเมลของคุณ"}
                    emailError={'กรุณาระบุอีเมลให้ถูกต้อง'}
                    placeholder={""}
                    onChange={(e) => { formRegister.email = e.currentTarget.value; }}
                    minLength={0} />
                </div>
                <div className="form-group">
                  <label className="label" form="member-email">
                    รหัสผ่าน
                  </label>
                  <FormInput id={"password"}
                    type={"password"}
                    required={true}
                    placeholder={""}
                    tooShortError={"รหัสผ่าน (โปรดใช้อักขระอย่างน้อย 8 ตัว)"}
                    noInputError={"โปรดระบุรหัสผ่านของคุณ"}
                    onChange={(e) => { formRegister.password = e.currentTarget.value; }}
                    minLength={8} />
                </div>
                <div className="form-group">
                  <label className="label" form="member-email">
                    ยืนยันรหัสผ่าน
                  </label>
                  <FormInput id={"confirm-password"}
                    type={"password"}
                    required={true}
                    placeholder={""}
                    tooShortError={"รหัสผ่าน (โปรดใช้อักขระอย่างน้อย 8 ตัว)"}
                    noInputError={"โปรดระบุรหัสผ่านของคุณ"}
                    onChange={(e) => { formRegister.confirmPassword = e.currentTarget.value; }}
                    minLength={8} />
                </div>
                <button id="form-button" className="btn btn-solid btn-full btn-small" type="submit">
                  ลงทะเบียน
                </button>
              </form>
            </div>
            {callbackButton && (
              <div className="login-bottom">
                <div className="color-white row justify-content-center">
                  เป็นสมาชิกแล้ว?
                  &nbsp;
                  <button onClick={callbackButton} className="btn btn-small m-0 p-0 link-colorless color-white">
                    คลิกเพื่อเข้าสู่ระบบ
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
