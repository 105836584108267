import { FormEvent, useState } from "react";
import { getFacebookAuthToken, getGoogleAuthToken, loginApi, loginOrCreateApi } from "../apiNest/NestApiService";
import FormInput from "./formInput";
import ShowError from "./showError";
import Img from "./image";
import UserManager from "../auth/userManager";
import FacebookLogin, { ReactFacebookLoginInfo } from 'react-facebook-login';
import { Auth, AuthApiProps } from "../apiNest/models/content/auth";
import Link from "next/link";
import {CredentialResponse, GoogleLogin} from "@react-oauth/google";
import useWindowDimensions from "../functions/getWindowDimensions";

interface CustomLoginProp {
  path: string;
  callbackButton: Function;
  headerText: string;
}

export default function CustomLogin({ 
  path, 
  callbackButton, 
  headerText }: 
  CustomLoginProp) {
    const userManager = new UserManager();
  const [alertPassword, setAlertPassword] = useState(false);
  const [formLogin, setFormLogin] = useState({
    email: "",
  });
  const [password, setPassword] = useState("")
  const [errorLogin, setErrorLogin] = useState({
    isError: false,
    message: "",
  });
  const [userExits, setUserExits] = useState(false);

  async function loginRequest(event?: FormEvent) {
    event && event.preventDefault();
    setErrorLogin({
      isError: false,
      message: ""
    })
    if (userExits) {
      const formData: AuthApiProps = {
        username: formLogin.email,
        password: password
      }
      const data = await loginApi(formData) as Auth;
      if (data.message) {
        setErrorLogin({
          isError: true,
          message: "อีเมลหรือรหัสผ่านไม่ถูกต้องกรุณาลองใหม่หรือเข้าสู่ระบบด้วย Google/Facebook" ,
        })
        return
      }
      await userManager.saveToken(data.access_token, data.refresh_token);
      callbackButton(path)
    } else {
      const dataLogin = await loginOrCreateApi(formLogin);
      setUserExits(dataLogin.is_exist);
      if (!dataLogin.is_exist) {
        await userManager.saveToken(dataLogin?.access_token, dataLogin?.refresh_token);
        setAlertPassword(true);
      }
    }
  }

  const responseGoogle = async (response: CredentialResponse) => {
    getGoogleAuthToken({
      id_token: response.credential
    }).then((value) => {
      if ((value?.statusCode === 500 || !value)) {
        setErrorLogin({
          isError: true,
          message: "เข้าสู่ระบบล้มเหลว กรุณาลองใหม่อีกครั้ง"
        });
        return
      }
      userManager.saveToken(value!.access_token, value!.refresh_token).then(() => {
        callbackButton(path);
      });
    });
  }

  const responseFacebook = async (response: ReactFacebookLoginInfo) => {
    if (response.accessToken) {
      getFacebookAuthToken({
        access_token: response.accessToken as string
      }).then((value) => {
        if ((value?.statusCode === 500 || !value)) {
          setErrorLogin({
            isError: true,
            message: "เข้าสู่ระบบล้มเหลว กรุณาลองใหม่อีกครั้ง"
          });
          return
        }
        userManager.saveToken(value!.access_token, value!.refresh_token).then(() => {
          callbackButton(path);
        });
      });
    }
  }

  if (alertPassword) {
    return (
      <div className="column-center">
        <h4 className="color-white">
          ระบบได้ส่งรหัสผ่านไปยังอีเมลของคุณ
        </h4>
        <h6 className="color-white">
          กรุณาตรวจสอบอีเมล
        </h6>
        <button
          onClick={() => { callbackButton(path) }}
          className="btn">
          ดำเนินการต่อ
        </button>
      </div>
    )
  }

  const GoogleLoginWidth = () => {
    const { width } = useWindowDimensions();
    return width<400? `${width-60}px` : '360px';
  }

  return (
    <div>
      <h2 className="color-white text-center">
        {headerText}
      </h2>
      <div className="column-center">
        <GoogleLogin
          width={GoogleLoginWidth()}
          shape={'pill'}
          onSuccess={responseGoogle}
          onError={() => {
            console.error('Google Login Failed');
          }}
        />
       <FacebookLogin
          appId={process.env.NEXT_PUBLIC_NEXTAUTH_FACEBOOK_CLIENT_ID as string}
          callback={responseFacebook}
          cssClass="btn btn-icon btn-full m-b-10 m-x-0 background-color-facebook"
          containerStyle={{width: "100%", transition: "none"}}
          disableMobileRedirect={true}
          icon={
            <div className="icon-frame p-0">
            <Img src="/login/facebook-icon.png"
              width={30}
              height={30}
              alt="Facebook"
            />
          </div>
          }
          textButton="เข้าสู่ระบบด้วย Facebook"
        />
      </div>
      <hr />
      <div className="p-20">
        <div className="block-type-form text-center">
          <div className="block box-shadow-none">
            <div className="form">
              <form onSubmit={loginRequest}>
                {errorLogin.isError && (<ShowError message={errorLogin.message} />)}
                <div className="form-group">
                  <label className="label" form="member-email">
                    อีเมล
                  </label>
                  <FormInput id={"email"}
                    type={"email"}
                    required={true}
                    placeholder={""}
                    onChange={(e) => { formLogin.email = e.currentTarget.value; }}
                    minLength={0} />
                </div>
                {userExits ? (
                  <div className="form-group">
                    <label className="label" form="member-email">
                      รหัสผ่าน
                    </label>
                    <FormInput id={"password"}
                      type={"password"}
                      required={true}
                      placeholder={""}
                      onChange={(e) => { setPassword(e.currentTarget.value); }}
                      minLength={8} />
                  </div>)
                  : (
                    <div></div>
                  )}

                <button id="form-button" className="btn btn-solid btn-full btn-small" type="submit">
                  เข้าสู่ระบบ
                </button>
              </form>
            </div>
            <div className="login-bottom">
              <Link href={"/forgot-password"} passHref={true}>
                <a className="btn btn-small m-t-20 m-0 p-0 link-colorless color-white">
                  ลืมรหัสผ่าน?
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
