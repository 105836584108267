import { getUserProfileApi } from "../apiNest/NestApiService";

export interface RenewalData {
  librarybanner: LibraryBanner;
  landingpage: LandingPage;
}

export interface LibraryBanner {
  desktop: string;
  mobile: string;
}

export interface LandingPage {
  banner: string;
  dealimage: string;
  dealexpired: string;
  courses: Course[];
}

export interface Course {
  banner: string;
  title: string;
  status: string;
  bullets: string[];
  instructor: string;
}

export async function getRenewalData(): Promise<RenewalData> {
  const response = await fetch(
    "https://dl.dropboxusercontent.com/s/gz0w71elyybcl70/renewal_data.json?dl=0",
    {
      method: "GET",
    }
  );
  return response.json();
}

export type RenewalUser = {
  status: boolean;
  renewal: boolean;
  cid: string;
  email: string;
};

export async function getRenewalUser(): Promise<RenewalUser | null> {
  const data = await getUserProfileApi();
  if (!data) {
    return null;
  }
  const response = await fetch(
    `https://script.google.com/macros/s/AKfycbwbNRiayb360Ua0PqccnDyyKVrRHTeb9TVTEwzqxL2cr1z9MTfSpZk0v4A3APdrYDPG/exec?cid=${
      data.id}`,
    {
      method: "GET",
    }
  );
  return await response.json();
}