import { useEffect, useState } from "react";

interface ShowErrorProps {
  message: string;
}

export default function ShowError({ message }: ShowErrorProps) {
  const [ messageLocal, setMessageLocal ] = useState(message);

  useEffect(() => {
    setMessageLocal(message);
  }, [message]); 

  return (
    <div className="auth-message alert alert-danger">
      {messageLocal}
    </div>
  )
}
